export const socialLinks = [
  {
    id: 1,
    href: 'https://www.instagram.com/justdive._/',
    icon: 'bx bxl-instagram-alt',
  },
  { id: 2, href: 'https://wa.me/+918762412121', icon: 'bx bxl-whatsapp' },
  { id: 3, href: 'tel:+918762412121', icon: 'bx bxs-phone' },
];

export const Testimonial = [
  {
    id: 1,
    review: `"I had the most incredible scuba diving adventure with JustDive! Abhishek R. , a fellow thrill-seeker, and I couldn't have asked for a better experience. The team at JustDive provided top-notch
    guidance, ensuring our safety while allowing us to explore the
    mesmerizing underwater world. Abhishek and I are already
    planning our next dive with JustDive—it's an adventure like no
    other!"`,
    person: `Subham D.  - The Thrill-Seeker`,
  },
  {
    id: 2,
    review: `"I embarked on an unforgettable underwater journey with JustDive. The sheer beauty of the dive sites, coupled with the expertise of the JustDive team, made for an exceptional experience. Subham and I discovered hidden wonders beneath the waves, thanks to the seamless organization and professionalism of JustDive. This won't be our last dive with them!"`,
    person: `Abhishek R.   - The Underwater Explorer`,
  },
  {
    id: 3,
    review: `"I share a deep love for the ocean, and our recent dive with JustDive exceeded all expectations. The enthusiasm and passion for marine life were perfectly complemented by the expertise of the JustDive team. From colorful coral reefs to majestic sea creatures, every moment was magical. JustDive truly knows how to create an experience that resonates with ocean enthusiasts like Carlos and me."`,
    person: `Shouvik B.  - The Ocean Enthusiast`,
  },
  {
    id: 4,
    review: `"I'm a novice diver, and I recently took the plunge with JustDive, and the transformation in my confidence and enthusiasm was remarkable. JustDive's patient and skilled instructors made me feel comfortable and empowered underwater. The personalized attention and support I received turned me into a true diving enthusiast. Kudos to JustDive for turning a first-time diver into a lifelong fan!"`,
    person: `Samantha L.  - The Novice Diver Turned Enthusiast.`,
  },
  {
    id: 5,
    review: `"JustDive provided the perfect setting for a family adventure. Our recent dive, surrounded by the breathtaking underwater landscapes, was a testament to the family-friendly atmosphere created by JustDive. The attentive staff, well-maintained equipment, and stunning dive locations made it an unforgettable experience for me and my family. JustDive truly caters to adventurers of all ages!"`,
    person: `Jagdishwar P.  - The Family Adventurer.`,
  },
];
