import React, { useState } from "react";

const MyCourses = ({ courseItem, courseData }) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <div className="main-heading">
        <p
          onClick={() => {
            setShow(!show);
          }}
        >
          {show ? (
            <i className="bx bx-minus" style={{ color: "red" }}></i>
          ) : (
            <i className="bx bx-plus" style={{ color: "green" }}></i>
          )}
        </p>
        <h3>{courseItem}</h3>
      </div>
      {show && <p className="answers">{courseData}</p>}
    </>
  );
};

export default MyCourses;
