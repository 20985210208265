import React, { useState,useEffect } from "react";
import { socialLinks } from "../../data";
import retrieveDataFromS3 from '../../aws-config.js';



const paragraphStyles = {
  WebkitLineClamp: 8,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  display: "-webkit-box",
};

const Blog = () => {

 const [scubaPNG, setScubaPNG] = useState('');
 const [scubaPNG55, setScubaPNG55] = useState('');
  useEffect(() => {
    retrieveDataFromS3('scubaPNG-removebg-preview.png').then((url) => {
      setScubaPNG(url);
    });
    retrieveDataFromS3('scubaPNG5-removebg-preview.png').then((url) => {
      setScubaPNG55(url);
    });
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const [isKhula, setIsKhula] = useState(false);
  const [open, setOpen] = useState(false);
  const [khula, setKhula] = useState(false);

  return (
    <>
      <section className="blog">
        <div className="main-section">
          <div className="blog-left">
            <div className="main-1">
              <h2>Scuba Diving & India</h2>
              <p style={isOpen ? null : paragraphStyles}>
                If you're seeking a captivating adventure that mirrors the
                sensations of space travel, look no further than scuba diving.
                Many dreams for interplanetary expedition, but for now, scuba
                diving serves as a terrestrial alternative to experience the
                thrill of exploring a different world. Similar to astronauts
                preparing for space by undergoing deep-sea expeditions to
                acclimate to pressure changes, scuba diving allows individuals
                to adjust to an environment that shares some parallels with
                outer space.
                <br /> <br />
                If you're seeking a captivating adventure that mirrors the
                sensations of space travel, look no further than scuba diving.
                Many dreams for interplanetary expedition, but for now, scuba
                diving serves as a terrestrial alternative to experience the
                thrill of exploring a different world. Similar to astronauts
                preparing for space by undergoing deep-sea expeditions to
                acclimate to pressure changes, scuba diving allows individuals
                to adjust to an environment that shares some parallels with
                outer space.
                <br /> <br />
                In India, various locations offer scuba diving centers, each
                providing a gateway to the underwater world. Among these,
                Murdeshwar in Karnataka stands out as a prominent destination
                for scuba enthusiasts. A mere 40-kilometer boat ride from
                Murdeshwar takes you to Netrani Island, a haven for scuba diving
                and water activities. The island's underwater wonders, including
                vibrant corals and diverse marine life, make it an ideal spot
                for those seeking the best scuba diving experiences.
                <br /> <br />
                For those eager to learn scuba diving in India, certified
                centers in Andaman, Maharashtra, Goa, Karnataka, Kerala, and
                Pondicherry provide the necessary training. It's noteworthy that
                swimming is not a mandatory prerequisite for scuba diving;
                certified instructors guide participants through the basics,
                ensuring a safe and enjoyable experience.
                <br /> <br />
                To make the most of your scuba diving adventure in Murdeshwar,
                plan your visit between October and April, the optimal time for
                underwater exploration. However, individuals with health
                concerns related to breathing or physical ability should undergo
                a medical test before engaging in scuba diving. Certified
                trainers ensure that participants are well-prepared and capable
                of undertaking this exhilarating activity.
                <br /> <br />
                So, if you're yearning for an escape from the ordinary and a
                chance to find your own version of Nemo, pack your bags and head
                to Murdeshwar for an unforgettable scuba diving experience at
                Netrani Island. Explore the depths, marvel at the underwater
                world, and let scuba diving be your ticket to a new and
                enchanting dimension.
              </p>
              <button className="read" onClick={() => setIsOpen(!isOpen)}>
                {isOpen ? "Read Less" : "Read More"}
              </button>
            </div>
            <div className="main-2">
              <h2>General Instructions</h2>
              <p style={isKhula ? null : paragraphStyles}>
                <b>Certification:</b> Look for instructors certified by
                reputable organizations like PADI, SSI, NAUI, or other
                recognized agencies. Their certification ensures they've met
                specific standards for teaching scuba diving.
                <br /> <br />
                <b>Experience: </b> Prioritize instructors with ample
                experience, especially in the areas where you plan to dive.
                Experienced instructors often provide more nuanced guidance and
                handle various diving situations adeptly.
                <br /> <br />
                <b>Safety Record: </b> Investigate their safety track record.
                Instructors should prioritize safety protocols, possess
                emergency training, and have a solid safety record.
                <br /> <br />
                <b>Teaching Style:</b> A good instructor communicates clearly
                and adapts teaching methods to suit different learning styles.
                Find someone patient, attentive, and who makes you feel
                comfortable underwater.
                <br /> <br />
                <b>Equipment Knowledge: </b> Instructors should have thorough
                knowledge of diving equipment and maintenance. They should teach
                you how to use gear properly and ensure its proper functioning
                before dives.
                <br /> <br />
                <b>References or Reviews:</b> Check reviews or ask for
                references from previous students. Hearing about others'
                experiences can give you insight into an instructor’s teaching
                style and professionalism.
                <br /> <br />
                <b>Passion and Enthusiasm: </b> An instructor passionate about
                diving often imparts not just technical knowledge but also a
                deep appreciation for the underwater world.
              </p>
              <button className="read" onClick={() => setIsKhula(!isKhula)}>
                {isKhula ? "Read Less" : "Read More"}
              </button>
            </div>
          </div>
          <div className="blog-right">
            <div className="main-1">
              <h2>Top 5 things to do in Murdeshwar</h2>

              <p style={open ? null : paragraphStyles}>
                <b>1. Visit Murdeshwar Temple: </b>Explore the famous Murdeshwar
                Temple, known for its towering gopuram and the stunning Shiva
                statue overlooking the Arabian Sea.
                <br />
                <br />
                <b>2. Relax at Murdeshwar Beach: </b>Enjoy the serene Murdeshwar
                Beach, offering a picturesque setting for a leisurely stroll or
                a peaceful day by the sea. The busy beaches in Goa and now
                taking over Gokarna has made people to find beaches with peace.
                <br />
                <br />
                <b>3. Scenic Views from Murdeshwar Port:</b> Climb up to
                Murdeshwar Port for panoramic views of the coastline and the
                temple. It's a great spot for photography enthusiasts.
                <br />
                <br />
                <b>4. Water Activities at Netrani Island (Just Dive): </b> Take
                a boat trip to Netrani Island which is 20 km away. It's a small
                island with clear water for thrilling water activities like
                snorkeling and scuba diving amidst vibrant marine life. To have
                a pleasant and safe dive, you can choose Just dive sea
                adventures with PADI certification and well experienced divers
                to accompany you. They offer many services and are at affordable
                pricing.
                <br />
                <br />
                <b>5. Explore Kollur Mookambika Temple: </b>If you have time,
                consider visiting the nearby Kollur Mookambika Temple, a
                significant pilgrimage site dedicated to Goddess Mookambika.
              </p>
              <button className="read" onClick={() => setOpen(!open)}>
                {open ? "Read Less" : "Read More"}
              </button>
            </div>
            <div className="main-2">
              <h2>Do's & Don'ts for Scuba Diving</h2>
              <p style={khula ? null : paragraphStyles}>
                <span>Do's:</span>
                <b>Get Certified: </b> Undertake proper training and get
                certified by a recognized scuba diving agency.
                <br />
                <br />
                <b>Plan and Check Equipment: </b> Prior to diving, ensure your
                equipment is in good condition and functioning properly.
                <br />
                <br />
                <b>Dive with a Buddy: </b> Always dive with a partner to enhance
                safety and assistance in case of emergencies.
                <br />
                <br />
                <b>Equalize Pressure: </b> Equalize your ears and mask as you
                descend to prevent discomfort or injury.
                <br />
                <br />
                Monitor Air Supply: Keep a close eye on your air supply and
                practice controlled breathing to maximize dive time.
                <br />
                <br />
                <b>Observe Marine Life Respectfully: </b> Enjoy marine life but
                maintain a safe distance and avoid touching or disturbing
                animals and corals.
                <br />
                <br />
                <b>Practice Buoyancy Control: </b> Master buoyancy control to
                conserve energy and minimize damage to the underwater
                environment.
                <br />
                <br />
                <span>Don'ts:</span>
                <br />
                <br />
                <b>Hold Your Breath: </b> Never hold your breath while
                ascending; always breathe continuously and ascend slowly.
                <br />
                <br />
                <b>Rush or Panic: </b> Avoid rapid ascents or panicked
                movements, as they can lead to accidents or decompression
                sickness.
                <br />
                <br />
                <b>Dive Beyond Limits: </b> Always dive with a partner to
                enhance safety and assistance in case of emergencies.
                <br />
                <br />
                <b>Touch Marine Life: </b> Refrain from touching, feeding, or
                harassing marine creatures or corals.
                <br />
                <br />
                Ignore Dive Briefings: Always pay attention to dive briefings
                and adhere to guidelines provided by your instructor or dive
                leader.
                <br />
                <br />
                <b>Overexert Yourself: </b> Enjoy marine life but maintain a
                safe distance and avoid touching or disturbing animals and
                corals.
                <br />
                <br />
                <b>Drink Alcohol Before Diving: </b> Avoid alcohol consumption
                before diving, as it can impair judgment and increase
                dehydration risks.
                <br />
                <br />
                Adhering to these guidelines promotes safety, preserves the
                underwater environment, and enhances your overall diving
                experience.
              </p>

              <button className="read" onClick={() => setKhula(!khula)}>
                {khula ? "Read Less" : "Read More"}
              </button>
            </div>
          </div>
        </div>

        <img src={scubaPNG55} alt="" className="scubaPNG55" />
        <img src={scubaPNG} alt="" className="scubaPNG" />


      </section>

      <div className="nav-icons">
          {socialLinks.map((link) => {
            const { id, href, icon, color } = link;
            return (
              <li key={id}>
                <a
                  href={href}
                  target="_blank"
                  rel="noreferrer"
                  className="nav-icon"
                  color={color}
                >
                  <i className={icon}></i>
                </a>
              </li>
            );
          })}
        </div>
    </>
  );
};

export default Blog;
