import React, { useEffect, useState } from 'react';
import retrieveDataFromS3 from '../aws-config.js';
import { socialLinks } from "../data";

const Home = () => {
  const [videoUrl, setVideoUrl] = useState('');
  useEffect(() => {
    retrieveDataFromS3('scuba_diving.mp4').then((url) => {
      setVideoUrl(url);
    });
  }, []);

  return (
    <>
      <div className="hero" id="hero">
        <div className="overlay"></div>
         {videoUrl && (
        <video
          className="videoTag"
          src={videoUrl}
          autoPlay
          loop
          muted
          playsInline
          id="background-video"
        />
      )}
        <div className="hero-text">
          <h1>JustDive</h1>
          <h3>Where Thrills Meet Tranquility</h3>
          <p>
            Discover the depths! Dial +91-8762&nbsp;<b>4</b>&nbsp;12121 /
            +91-8762&nbsp;<b>5</b>&nbsp;12121 for scuba thrills now!
          </p>
        </div>
        <div className="nav-icons">
          {socialLinks.map((link) => {
            const { id, href, icon, color } = link;
            return (
              <li key={id}>
                <a
                  href={href}
                  target="_blank"
                  rel="noreferrer"
                  className="nav-icon"
                  color={color}
                >
                  <i className={icon}></i>
                </a>
              </li>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Home;
