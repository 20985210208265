import React,{useState,useEffect} from "react";
import { Link } from "react-router-dom";
import retrieveDataFromS3 from '../../aws-config.js';

const removeHandler = () =>{
  window.scrollTo(0,0);
}


const Footer = () => {

   const [scubaPNG14, setScubaPNG14] = useState('');
 const [scubaPNG1, setScubaPNG1] = useState('');
  useEffect(() => {
    retrieveDataFromS3('scubaPNG14.png').then((url) => {
      setScubaPNG14(url);
    });
    retrieveDataFromS3('scubaPNG1-removebg-preview.png').then((url) => {
      setScubaPNG1(url);
    });
  }, []);
  return (
    <section className="footer" id="footer">
      <div className="footer__container">
        <div className="box">
          <h3>About Us</h3>
          <p>
            JustDive, the leading dive center in Murdeshwar, is your gateway to
            extraordinary underwater adventures. With a commitment to
            excellence, we are proud to be the only dive center in the area
            offering proper PADI certificates and courses. Dive with us and
            explore the captivating wonders beneath the surface with confidence
            and expertise. Feel free to call us on <b>+918762412121 </b> for any
            query.
          </p>
        </div>
        <div className="box">
          <h3>Quick Links</h3>
          <ul>
            <li>
            <Link  onClick={removeHandler} to="/"  >Home</Link>
            </li>
            <li>
            <Link  onClick={removeHandler} to='/whatwedo' >Services</Link>
            </li>
            <li>
            <Link  onClick={removeHandler} to='/courses' >Courses</Link>
            </li>
            <li>
            <Link  onClick={removeHandler} to='/about' >About Us</Link>
            </li>
            <li>
            <Link  onClick={removeHandler} to='/gallery' >Gallery</Link>
            </li>
            <li>
            <Link  onClick={removeHandler} to='/blogs' >Blog</Link>
            </li>
          </ul>
        </div>
      </div>
      <img src={scubaPNG14} alt="" className="scubaPNG14" />
      <img src={scubaPNG1} alt="" className="scubaPNG1" />
    </section>
  );
};

export default Footer;
