export const course = [
  {
    id: 1,
    courseItem: "Open Water Diver Course",
    courseData:
      "Dive into the world of scuba with our Open Water Diver Course at JustDive. Gain the skills and knowledge to explore the depths of the ocean. Our expert instructors will lead you through theory, pool sessions, and thrilling open water dives. Earn your certification and unlock a lifetime of underwater adventures. Immerse yourself in vibrant marine ecosystems and create memories that will last a lifetime. Join us and begin your journey into the mesmerizing realm of scuba diving.",
  },
  {
    id: 2,
    courseItem: " Advanced Open Water Diver Course",
    courseData:
      "Take your diving skills to new heights with our Advanced Open Water Diver Course at JustDive. Expand your knowledge and confidence as you explore thrilling dive scenarios. Master advanced techniques such as deep diving, underwater navigation, and night diving. Our experienced instructors will guide you through exciting underwater challenges, unlocking a world of underwater exploration. Become a certified advanced diver and uncover the wonders hidden beneath the waves. Join us for an exhilarating dive adventure like no other.",
  },
  {
    id: 3,
    courseItem: "Emergency First Responder Course",
    courseData:
      "Equip yourself with life-saving skills through our Emergency First Responder Course at JustDive. Learn essential first aid, CPR, and emergency response techniques specifically tailored for dive-related incidents. Gain the confidence to handle emergencies on land and in the water. Our certified instructors will provide comprehensive training, ensuring you're prepared to assist in critical situations. Be the first line of response and ensure the safety of yourself and others. Enroll now and become a certified Emergency First Responder.",
  },
  {
    id: 4,
    courseItem: "Rescue Diver Course",
    courseData:
      "Dive into the extraordinary with our Rescue Diver Course at JustDive. Designed for seasoned divers, this comprehensive program enhances your skills in emergency response and crisis management underwater. Learn to anticipate, prevent, and manage potential diving emergencies, ensuring the safety of yourself and fellow divers. Our expert instructors guide you through realistic scenarios, equipping you with the confidence and skills needed to handle challenging situations beneath the waves. Unleash your potential with JustDive's Rescue Diver Course.",
  },

  {
    id: 5,
    courseItem: "Master Scuba Diver Course",
    courseData:
      "Depends on speciality Unleash the true master diver within you with our Master Scuba Diver Course at JustDive. Elevate your skills and expertise to the highest level in recreational diving. Expand your knowledge with specialized training in underwater navigation, deep diving, enriched air diving, and more. Accomplish a milestone as you earn the prestigious Master Scuba Diver certification, demonstrating your mastery in the underwater realm. Join us on this extraordinary adventure and become the epitome of diving excellence.",
  },


  {
    id: 6,
    courseItem: "Divemaster Course",
    courseData:
      "Take the leap towards becoming a diving professional with our Divemaster Course at JustDive. Immerse yourself in the art of leadership and fine-tune your dive skills to a professional level. Gain extensive knowledge in dive theory, underwater navigation, and safety protocols. Under the guidance of our experienced instructors, you'll develop the expertise to lead and assist divers in various environments. Join us on this transformative journey and unlock a world of endless possibilities in the diving industry.",
  },
];
