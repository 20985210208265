import Testimonials from "../Testimonials/Testimonials";
import Home from "../../Pages/Home";

const Hero = () => {
  console.log(process.env.AWS_S3_BUCKET);
  return (
    <div>
      <Home />
      <Testimonials />
    </div>
  );
};

export default Hero;
