import React,{useEffect,useState} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Testimonial } from "../../data";
import retrieveDataFromS3 from '../../aws-config.js';

const Testimonials = () => {

     const [scubaPNG8, setScubaPNG8] = useState('');
 const [scubaPNG10, setScubaPNG10] = useState('');
  useEffect(() => {
    retrieveDataFromS3('scubaPNG8-removebg-preview.png').then((url) => {
      setScubaPNG8(url);
    });
    retrieveDataFromS3('scubaPNG10-removebg-preview.png').then((url) => {
      setScubaPNG10(url);
    });
  }, []);
  return (
    <section className="testimonials" id="testimonial">
      <h2>
         <span>Testimonial</span>
      </h2>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={50}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        loop={true}
        // scrollbar={{ draggable: true }}
      >
        {Testimonial.map((data) => {
          const { id, review, person } = data;
          return (
            <SwiperSlide key={id}>
              <div className="testimonialInnerText">
                <p className="testimonialText">{review}</p>
                <h3 className="testimonialPerson">{person}</h3>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <img src={scubaPNG8} alt="" className="scubaPNG8"/>
      <img src={scubaPNG10} alt="" className="scubaPNG10"/>
    </section>
  );
};

export default Testimonials;
