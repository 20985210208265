import { useState, useEffect } from "react";
import "./Gallery.css";
import { socialLinks } from "../../data";
import retrieveDataFromS3 from '../../aws-config.js';
import AWS from 'aws-sdk';

// Configure AWS with your credentials
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_S3_ACCESSKEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_S3_SECRETACCESSKEY,
  region: process.env.REACT_APP_AWS_S3_REGION,
});

// Create S3 client
const s3 = new AWS.S3();

const listObjectsInFolder = async () => {
  const params = {
    Bucket: process.env.REACT_APP_AWS_S3_BUCKET,
    Prefix: 'galary/', // Specify the folder name
  };

  try {
    const data = await s3.listObjectsV2(params).promise();
    const objects = data.Contents;

    // Filter out only objects with a valid key (not the folder itself)
    const validObjects = objects.filter((object) => object.Key !== params.Prefix);

    // Retrieve URLs and determine the type for each object
    const galleryArray = await Promise.all(validObjects.map(async (object, index) => {
      const img = await retrieveDataFromS3(object.Key);

      // Determine the file type based on the file extension
      const fileType = object.Key.split('.').pop().toLowerCase(); // Get the file extension
      const type = fileType === 'mp4' || fileType === 'mov' ? 'video' : 'image';

      return {
        id: index + 1,
        img,
        type,
      };
    }));

    return galleryArray;
  } catch (error) {
    console.error('Error listing objects in S3:', error);
    throw error; // Propagate the error
  }
};

const Popup = ({ file, onClose }) => {


  return (
     <div className="popup-media" style={{ display: file ? "block" : "none" }}>
      <span onClick={onClose}>&times;</span>

      {file?.type === "video" ? (
        <video src={file?.img} muted autoPlay controls />
      ) : (
        <img src={file?.img} alt="" />
      )}
    </div>
  );
};

const Gallery = () => {
  const [files, setFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    const fetchGallery = async () => {
      const gallery = await listObjectsInFolder();
      setFiles(gallery);
    };

    fetchGallery();
  }, []); // Run once on component mount

  const openFile = (file) => {
     setSelectedFile(file);
  };
   const closePopup = () => {
    setSelectedFile(null);
  };


  return (
    <section className="gallery container">
      <h2> Media <span>Gallery</span></h2>

      <div className="media-container">
        {files.map((file) => (
          <div className="media" key={file.id} onClick={() => openFile(file)}>
            {file.type === "image" ? (
              <img src={file.img} alt="" />
            ) : (
              <video src={`${file.img}#=0.001`} autoPlay loop muted playsInline preload="metadata" />
            )}
          </div>
        ))}
      </div>

      <div className="nav-icons">
        {socialLinks.map((link) => {
          const { id, href, icon, color } = link;
          return (
            <li key={id}>
              <a
                href={href}
                target="_blank"
                rel="noreferrer"
                className="nav-icon"
                color={color}
              >
                <i className={icon}></i>
              </a>
            </li>
          );
        })}
      </div>
      {selectedFile && (
        <Popup file={selectedFile} onClose={closePopup} />
      )}
    </section>
  );
};

export default Gallery;