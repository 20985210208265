import React,{useState, useEffect} from "react";
import { socialLinks } from "../../data";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Link } from "react-router-dom";
import retrieveDataFromS3 from '../../aws-config.js';


const WhatWeDo = () => {
 const [scubaPNG5, setScubaPNG5] = useState('');
  const [scubaPNG6, setScubaPNG6] = useState('');
  const [boating, setBoating] = useState('');
  const [islandtrip, setIslandtrip] = useState('');
  const [snorkeling, setSnorkeling] = useState('');
  const [scubadive, setScubadive] = useState('');
  const [bookingImages, setBookingImages] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setScubaPNG5(await retrieveDataFromS3('scubaPNG5-removebg-preview.png'));
      setScubaPNG6(await retrieveDataFromS3('scubaPNG6-removebg-preview.png'));
      setBoating(await retrieveDataFromS3('boating.mp4'));
      setIslandtrip(await retrieveDataFromS3('islandtrip.mp4'));
      setSnorkeling(await retrieveDataFromS3('snorkeling.mp4'));
      setScubadive(await retrieveDataFromS3('jd.mp4'));
      await fetchBookingImages();
    };

    fetchData();
  }, []);

  const removeHandler = () => {
    window.scrollTo(0, 0);
  };

  const fetchBookingImages = async () => {
   const images = [
      { id: 1, img: await retrieveDataFromS3('booking1.png') },
      { id: 2, img: await retrieveDataFromS3('booking2.png') },
      { id: 3, img: await retrieveDataFromS3('booking3.png') },
      { id: 4, img: await retrieveDataFromS3('booking4.png') },
      { id: 5, img: await retrieveDataFromS3('booking5.png') },
      // add more items as needed
    ];

    setBookingImages(images);
  };
  return (
    <>
      <section className="services" id="services">
        <h2>
          What We <span>Do</span>
        </h2>
        <div className="services-section">
          <div className="service">
            <div className="item">
              <div className="service__text">
                <video
                  className="videoTag"
                  src={boating}
                  autoPlay
                  loop
                  muted
                  playsInline
                  id="background-video"
                />
                <h3>Boating</h3>
                <p>
                  Embark on an exciting boating excursion with JustDive. Cruise
                  along picturesque coastlines, visit hidden coves, and indulge
                  in the thrill of exploring the ocean. Join us for an
                  unforgettable maritime journey!
                </p>
              </div>
            </div>
            {/* <!-- ///////////////// --> */}
            <div className="item">
              <div className="service__text">
                <video
                  className="videoTag"
                  src={snorkeling}
                  autoPlay
                  loop
                  muted
                  playsInline
                  id="background-video"
                />
                <h3>Snorkeling</h3>
                <p>
                  Dive into the wonders of snorkeling with JustDive. Discover
                  stunning reefs, colorful fish, and crystal-clear waters. Begin
                  your aquatic adventure today and make a splash!
                </p>
              </div>
            </div>
            {/* <!-- ///////////////// --> */}
            <div className="item">
              <div className="service__text">
                <video
                  className="videoTag"
                  src={islandtrip}
                  autoPlay
                  loop
                  muted
                  playsInline
                  id="background-video"
                />
                <h3>Island trip</h3>
                <p>
                  Experience the ultimate island trip with our scuba diving
                  business. Explore pristine waters, vibrant marine life, and
                  create unforgettable memories. Book your adventure now!
                </p>
              </div>
            </div>
            {/* <!-- ///////////////// --> */}
            <div className="item">
              <div className="service__text">
                <video
                  className="videoTag"
                  src={scubadive}
                  autoPlay
                  loop
                  muted
                  playsInline
                  id="background-video"
                />
                <h3>Scuba Dive</h3>
                <p>
                  Plunge into the depths of adventure with JustDive. Immerse
                  yourself in a world of wonder, explore captivating underwater
                  landscapes, and witness the awe-inspiring beauty of marine
                  life. Dive in today!
                </p>
              </div>
            </div>
            {/* <!-- ///////////////// --> */}

            <img src={scubaPNG5} alt="" className="scubaPNG5" />
            <img src={scubaPNG6} alt="" className="scubaPNG6" />
          </div>
        </div>

        <div className="nav-icons">
          {socialLinks.map((link) => {
            const { id, href, icon, color } = link;
            return (
              <li key={id}>
                <a
                  href={href}
                  target="_blank"
                  rel="noreferrer"
                  className="nav-icon"
                  color={color}
                >
                  <i className={icon}></i>
                </a>
              </li>
            );
          })}
        </div>
      </section>

      <section className="booking">
        <h2>Book With Us Now</h2>

        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={50}
          slidesPerView={1}
          navigation
          loop={false}
          // scrollbar={{ draggable: true }}
        >
          {bookingImages.map((book) => {
            return (
              <SwiperSlide key={book.id}>
                <div className="bookingInnerText">
                  <div className="bookingInnerImg" alt="booking Image">
                    <img src={book.img} alt="" />
                  </div>

                </div>

              </SwiperSlide>
            );
          })}
        </Swiper>
        <Link to='/booknow' onClick={removeHandler}>Book Now</Link>
      </section>
    </>
  );
};

export default WhatWeDo;
