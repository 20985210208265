import React,{useEffect,useState } from "react";
import retrieveDataFromS3 from '../aws-config.js';

const Location = () => {
  const [scubaPNG2, setScubaPNG2] = useState('');
  useEffect(() => {
    retrieveDataFromS3('scubaPNG2.png').then((url) => {
      setScubaPNG2(url);
    });
  }, []);

  return (
    <section className="location" id="location">
      <h2>Locate Us</h2>
      <div className="row">
        <iframe
          className="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15479.215419061942!2d74.48750071781618!3d14.08875314831814!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bbc47590cd2dbad%3A0x30cd401f14afb042!2sJust%20Dive!5e0!3m2!1sen!2sin!4v1701010093262!5m2!1sen!2sin"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          style={{ border: "0rem" }}
          // referrerpolicy="no-referrer-when-downgrade"
        ></iframe>

        <div className="main-frame">
          Netaji SB Rd, <br />
          Beside Sea View Beach Resort, <br />
          Matadahitlu, Murdeshwar, <br />
          Karnataka 581350
        </div>
      </div>
      <img src={scubaPNG2} alt="" className="scubaPNG2"  />
    </section>
  );
};

export default Location;
