import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import retrieveDataFromS3 from '../../aws-config.js';

const Navbar = () => {
  const [logo, setLogo] = useState('');
  useEffect(() => {
    retrieveDataFromS3('logo.png').then((url) => {
      setLogo(url);
    });
  }, []);
  const navbarRef = useRef();
  const navbarHandler = () => {
    navbarRef.current.classList.toggle("active");
  };

  const removeHandler = () => {
    navbarRef.current.classList.remove("active");
    window.scrollTo(0, 0);
  };

  const [header, setHeader] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setHeader(true);
    } else {
      setHeader(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <header className={header ? "header active" : "header"}>
      <a href="#hero" className="logo">
        <img src={logo} alt="" />
      </a>

      <ul className="navlist" ref={navbarRef}>
        <li>
          <Link to="/" onClick={removeHandler}>
            Home
          </Link>
        </li>
        <li>
          <Link to="/whatwedo" onClick={removeHandler}>
            Services
          </Link>
        </li>
        <li>
          <Link to="/courses" onClick={removeHandler}>
            Courses
          </Link>
        </li>

        <li>
          <Link to="/about" onClick={removeHandler}>
            About Us
          </Link>
        </li>
        <li>
          <Link to="/gallery" onClick={removeHandler}>
            Gallery
          </Link>
        </li>
        <li>
          <Link to="/blogs" onClick={removeHandler}>Blog</Link>
        </li>
      </ul>

      <div className="bx bx-menu" id="menu-icon" onClick={navbarHandler}></div>
    </header>
  );
};

export default Navbar;
