import React,{useEffect, useState} from "react";
// import vidd from "../../assets/scubaID.mp4";
import { socialLinks } from "../../data";
import Location from "../../Pages/Location";
import retrieveDataFromS3 from '../../aws-config.js';

const About = () => {
  const [cover, setCover] = useState('');
  useEffect(() => {
    retrieveDataFromS3('cover.jpg').then((url) => {
      setCover(url);
    });
  }, []);

  return (
    <>
      <section className="about" id="about" style={{
         backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)), url(${cover})`
      }}>
        <div className="overlay"></div>
        <div className="content">
          <h2>
            About <span>Us</span>
          </h2>

          <p>
            At JustDive, we are passionate about scuba diving and sharing the
            awe-inspiring beauty of the underwater realm with enthusiasts like
            you. We are thrilled to announce the launch of our scuba diving
            company, dedicated to providing unforgettable experiences and
            creating lifelong memories.
            <br />
            <br />
            As a premier scuba diving destination, we offer a wide range of
            services tailored to divers of all levels, from beginners to
            experienced professionals. Our highly skilled and certified
            instructors are committed to ensuring your safety and providing
            top-notch training, so you can embark on your underwater adventures
            with confidence.
            <br />
            <br />
            Immerse yourself in a world of vibrant marine life, stunning coral
            reefs, and captivating shipwrecks. With JustDive, you'll have the
            opportunity to explore breathtaking dive sites carefully selected
            for their natural beauty and diversity.
          </p>
        </div>

        <div className="nav-icons">
          {socialLinks.map((link) => {
            const { id, href, icon, color } = link;
            return (
              <li key={id}>
                <a
                  href={href}
                  target="_blank"
                  rel="noreferrer"
                  className="nav-icon"
                  color={color}
                >
                  <i className={icon}></i>
                </a>
              </li>
            );
          })}
        </div>
      </section>
      <Location/>
    </>
  );
};

export default About;
