import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from './components/navbar/Navbar';
import Hero from './components/hero/Hero';
import Courses from './components/courses/Courses';
import WhatWeDo from './components/WhatWeDo/WhatWeDo';
import BookNow from './Pages/BookNow';
import About from './components/AboutUs/About';
import Gallery from './components/Gallery/Gallery';
import Footer from './components/Footer/Footer';
import Blog from './components/Blog/Blog';
import AWS from 'aws-sdk';

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_S3_ACCESSKEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_S3_SECRETACCESSKEY,
  region: process.env.REACT_APP_AWS_S3_REGION,
});

const s3 = new AWS.S3();

const setFavicon = async () => {
  const faviconKey = 'favicon.ico'; // Replace with the actual path to your favicon in the S3 bucket

  try {
    const faviconUrl = await s3.getSignedUrlPromise('getObject', {
      Bucket: process.env.REACT_APP_AWS_S3_BUCKET,
      Key: faviconKey,
    });

    const link = document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = faviconUrl;

    document.head.appendChild(link);
  } catch (error) {
    console.error('Error setting favicon:', error);
  }
};

const App = () => {
  useEffect(() => {
    setFavicon();
  }, []);
  return (
    <div>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Hero />} />
          <Route path="/whatwedo" element={<WhatWeDo />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/booknow" element={<BookNow />} />
          <Route path="/about" element={<About />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/blogs" element={<Blog />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default App;
