// aws-config.js

import AWS from 'aws-sdk';

// Configure AWS with your credentials
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_S3_ACCESSKEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_S3_SECRETACCESSKEY,
  region: process.env.REACT_APP_AWS_S3_REGION,
});

// Create S3 client
const s3 = new AWS.S3();

// Function to retrieve data from S3
const retrieveDataFromS3 = async (key) => {
  const params = {
    Bucket: process.env.REACT_APP_AWS_S3_BUCKET,
    Key: key,
  };

  try {
    const url = await s3.getSignedUrlPromise('getObject', params);
    return url;
  } catch (error) {
    console.error('Error retrieving data from S3:', error);
    throw error; // Propagate the error
  }
};

export default retrieveDataFromS3;
