import React,{useState, useEffect } from "react";
import { course } from "./api";
import MyCourses from "./MyCourses";
import { Link } from "react-router-dom";
import { socialLinks } from "../../data";
import retrieveDataFromS3 from '../../aws-config.js';



const Courses = () => {

   const [course1, setCourse1] = useState('');
   const [course2, setCourse2] = useState('');
   const [course3, setCourse3] = useState('');
   const [course4, setCourse4] = useState('');
   const [course5, setCourse5] = useState('');
   const [course6, setCourse6] = useState('');
   const [scubaPNG4, setScubaPNG4] = useState('');

  useEffect(() => {
    retrieveDataFromS3('course1.png').then((url) => {
      setCourse1(url);
    });
    retrieveDataFromS3('course2.png').then((url) => {
      setCourse2(url);
    });
    retrieveDataFromS3('course3.png').then((url) => {
      setCourse3(url);
    });
    retrieveDataFromS3('course4.png').then((url) => {
      setCourse4(url);
    });
    retrieveDataFromS3('course5.png').then((url) => {
      setCourse5(url);
    });
    retrieveDataFromS3('course6.png').then((url) => {
      setCourse6(url);
    });
    retrieveDataFromS3('scubaPNG4-removebg-preview.png').then((url) => {
      setScubaPNG4(url);
    });
  }, []);

  const removeHandler = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <section className="courses" id="courses">
        <h2>
          Our <span>Courses</span>
        </h2>
        {course.map((curElem) => {
          return <MyCourses key={curElem.id} {...curElem}  />;
        })}

        <div className="nav-icons">
          {socialLinks.map((link) => {
            const { id, href, icon, color } = link;
            return (
              <li key={id}>
                <a
                  href={href}
                  target="_blank"
                  rel="noreferrer"
                  className="nav-icon"
                  color={color}
                >
                  <i className={icon}></i>
                </a>
              </li>
            );
          })}
        </div>
          <img src={scubaPNG4} alt="" className="scubaPNG4"/>
      </section>

      <section className="myCourse" id="myCourse">
        <h2>Elevate Your Expertise Today</h2>

        <div className="course">
          <div className="courseInformation">
            <img src={course1} alt="" />
          </div>
          <div className="courseInformation">
            <img src={course2} alt="" />
          </div>
          <div className="courseInformation">
            <img src={course3} alt="" />
          </div>
          <div className="courseInformation">
            <img src={course4} alt="" />
          </div>
          <div className="courseInformation">
            <img src={course5} alt="" />
          </div>
          <div className="courseInformation">
            <img src={course6} alt="" />
          </div>
        </div>

        <Link to="/booknow" onClick={removeHandler}>Book Now</Link>
      </section>
    </>
  );
};

export default Courses;
