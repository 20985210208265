import React, { useState } from "react";
import { ToastContainer,toast } from 'react-toastify';
 import 'react-toastify/dist/ReactToastify.css';
 import { format } from "date-fns";
 import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const BookNow = () => {

  const [typeOfPackage, setTypeOfPackage] = React.useState("Services");

    const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone_no: "",
    no_of_people: "",
    booking_date: null,
    type: typeOfPackage === "Services" ? "Discover Scuba Diving" :'Open Water Diver Course',
  });

  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
   const [selectedDate, setSelectedDate] = useState(null);

    const currentDate = new Date();

 const handleDateChange = (date) => {
    setSelectedDate(date);
    setFormData({
      ...formData,
      booking_date: format(date, "dd/MM/yyyy"),
    });
  };

   const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
  e.preventDefault();
  setFormErrors(validate(formData));
  setIsSubmit(true);

  if (Object.keys(formErrors).length === 0 && isSubmit) {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT_URL}/booking`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(JSON.stringify(errorData.errors) || 'Booking failed');
      }

      toast.success('Booking Success !', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (err) {
      console.error(err);
      toast.error(err.message || 'An error occurred during booking');
    }
  }
};

   const validate = (values) => {
    const errors = {};
     const regexExp = /^[6-9]\d{9}$/gi;
    if(!values.name){
      errors.name = 'Name is required';
      toast.error( errors.name );
    }
    if(!values.email){
      errors.email = 'Email is required';
      toast.error( errors.email );
    }

    if(!values.phone_no){
      errors.phone_no = 'Phone No is required';
      toast.error( errors.phone_no );
    }else if(!regexExp.test(values.phone_no)){
      errors.phone_no = 'Please Enter Valid Phone No';
       toast.error( errors.phone_no );
    }

    if(!values.no_of_people){
      errors.no_of_people = 'Number of people is required';
       toast.error( errors.no_of_people );

    }

     if(!values.type){
      errors.type = 'Type is required';
        toast.error( errors.type );
    }

    return errors;
  }

  return (
    <section className="reservation" id="reservation">
       <ToastContainer />
      <h2>Make a Booking</h2>
      <div className="row">
        <p className="question">
          Are you looking for our Services or Courses ?
        </p>
        <select
          type="select"
          onChange={(event) => {
            console.log(event.target.value);
            setTypeOfPackage(event.target.value);
          }}
        >
          <option>Services</option>
          <option>Courses</option>
        </select>
        {typeOfPackage === "Services" ? (
          <form action="" method="post" onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Enter Your Name"
              maxLength="50"
              className="box"
              value={formData.name} onChange={handleChange}
            />
            <input
              type="email"
              name="email"
              placeholder="Enter Your email"
              maxLength="50"
              className="box"
              value={formData.email} onChange={handleChange}
            />
            <input
              type="number"
              name="phone_no"
              placeholder="Enter Phone Number"
              maxLength="10"
              min="0"
              max="99999999999"
              className="box"
              value={formData.phone_no} onChange={handleChange}
            />
            <input
              type="number"
              name="no_of_people"
              placeholder="Number of People"
              maxLength="10"
              min="0"
              max="40"
              className="box"
              value={formData.no_of_people} onChange={handleChange}
            />
             <DatePicker
        selected={selectedDate}
        name="booking_date"
      onChange={handleDateChange}
        dateFormat="yyyy-MM-dd"
        placeholderText="Date of booking"
         className="box"
          minDate={currentDate}
        required
      />
            <div className="box">

              <select name="type" className="input" value={formData.type} onChange={handleChange}>
                <option value="Discover Scuba Diving">Discover Scuba Diving</option>
                <option value="Discover Scuba Diving(Premium)">Discover Scuba Diving(Premium)</option>
                <option value="Fun Diving">Fun Diving</option>
                <option value="Snorkeling">Snorkeling</option>
                <option value="Bubble Maker">Bubble Maker</option>
                <option value="Boat Ride">Boat Ride</option>
              </select>
            </div>

            <input type="submit" value="Book Now" name="send" className="btn" />
          </form>
        ) : (
          <form action="" method="post" onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Enter Your Name"
              maxLength="50"
              className="box"
               value={formData.name} onChange={handleChange}
            />
            <input
              type="email"
              name="email"
              placeholder="Enter Your email"
              maxLength="50"
              className="box"
              value={formData.email} onChange={handleChange}
            />
             <input
              type="number"
              name="phone_no"
              placeholder="Enter Phone Number"
              maxLength="10"
              min="0"
              max="99999999999"
              className="box"
              value={formData.phone_no} onChange={handleChange}
            />
            <input
              type="number"
              name="no_of_people"
              placeholder="Number of People"
              maxLength="10"
              min="0"
              max="40"
              className="box"
               value={formData.no_of_people} onChange={handleChange}
            />
                 <DatePicker
        selected={selectedDate}
        name="booking_date"
      onChange={handleDateChange}
        dateFormat="yyyy-MM-dd"
        placeholderText="Date of booking"
         className="box"
          minDate={currentDate}
        required
      />

            <div className="box">

              <select name="type" className="input"  value={formData.type} onChange={handleChange}>
                <option value="Open Water Diver Course">Open Water Diver Course</option>
                <option value="Advanced Open Water Diver Course">Advanced Open Water Diver Course</option>
                <option value="Emergency First Responder Course">Emergency First Responder Course</option>
                <option value="Rescue Diver Course">Rescue Diver Course</option>
                <option value="Master Scuba Diver Course">Master Scuba Diver Course</option>
                <option value="Divemaster Course">Divemaster Course</option>
              </select>
            </div>

            <input type="submit" value="Book Now" name="send" className="btn" />
          </form>
        )}
      </div>
    </section>
  );
};

export default BookNow;
